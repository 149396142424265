import React, { createContext, useEffect, useState } from 'react'
import { convertFromAIGenerateToScript } from '../../utils/convertFromAiGenerateToScript';

const ChatContext = createContext();

const ChatContextProvider = ({ addScript, getPosition, children}) => {
  const [messages, setMessages] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const sendMessage = (message) => {
    setIsLoading(true);
    setMessages(messages.concat([{ type: 'user', message }]));
    window.top.postMessage({type: 'gen_script', message}, "*")
  }

  const value = {
    loading,
    messages,
    sendMessage,
  }

  useEffect(() => {
    const listener = (event) => {
      if (event.data.type === 'gen_script') {
        setIsLoading(false);
        const content = event.data.data?.content;
        if (content) {
          setMessages((msgs) => msgs.concat([{
            type: 'model',
            message: content,
          }]));
        }
        const script = event.data?.data.script
        if (script) {
          const position = getPosition()
          const dataEncode = convertFromAIGenerateToScript({ content: script, position })
          addScript(dataEncode)
        }
      }
    }
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener)
  }, [])

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  )
}

export { ChatContext, ChatContextProvider }
