import { useContext, useEffect, useRef } from 'react';
import { Textarea, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ChatContext } from '../context/ChatContext';
import ReactMarkdown from "react-markdown";

const Chat = () => {
  const { messages, sendMessage, loading } = useContext(ChatContext)
  const isEmptyMessage = !messages?.length
  const promtRef = useRef(null)
  const form = useForm({
    initialValues: { prompt: '' },
    validate: { prompt: (value) => !!value ? null : 'Type text to chat with AI' },
  });

  const handleKeyDown = (event) => {
    if (event.shiftKey && event.key === "Enter") {
      form.setFieldValue('prompt', form.getInputProps('prompt').value + '\n')
      return;
    }
    if (event.key === "Enter") {
      event.preventDefault();
      form.onSubmit(onSubmitForm)();
    }
    promtRef.current.focus()
  };

  const onSubmitForm = (values) => {
    form.setFieldValue('prompt', '');
    sendMessage(values.prompt);
  }

  useEffect(() => {
    promtRef.current.focus()
  }, [])

  return (
    <div
      style={{
        height: "calc(100vh - 92px)",
        position: 'relative',
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isEmptyMessage ? 'center' : 'normal'
      }}
    >
      <div
        style={{
          overflow: 'auto',
          fontSize: '0.85rem',
          flex: !isEmptyMessage ? 1 : 0,
        }}
      >
        {
          messages.map((mesage, idx) => mesage.type === 'model' ? (
            <p
              style={{
                padding: 6,
                marginTop: 12,
              }}
              key={idx}
            >
              <ReactMarkdown>{mesage.message}</ReactMarkdown>
            </p>
          ) : (
            <p
              style={{
                padding: 6,
                borderRadius: 12,
                marginTop: 12,
                textAlign: 'right',
                backgroundColor: '#006fe659',
              }}
              key={idx}
            >
              {mesage.message}
            </p>
          ))
        }
      </div>
      <form
        style={{
          width: '100%',
        }}
        onSubmit={form.onSubmit(onSubmitForm)}
      >
        {
          isEmptyMessage && (
            <Text
              variant="gradient"
              gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
              ta="center"
              fz="xl"
              fw={700}
              style={{ marginBottom: 8 }}
            >
              What can I help with?
            </Text>
          )
        }
        <Textarea
          disabled={loading}
          autosize
          minRows={3}
          style={{ width: '100%' }}
          onKeyDown={handleKeyDown}
          spellCheck={false}
          ref={promtRef}
          {...form.getInputProps('prompt')}
        />
      </form>
    </div>
  )
}

export default Chat
