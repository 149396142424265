import { chunk } from 'lodash'

export function compareDateVersions(version1, condition, version2) {
  const [day1_array, month1_array] = chunk(version1.split(''), 2)
  const [day2_array, month2_array] = chunk(version2.split(''), 2)
  const day1 = Number(day1_array.join(''))
  const month1 = Number(month1_array.join(''))
  const day2 = Number(day2_array.join(''))
  const month2 = Number(month2_array.join(''))

  switch (condition) {
    case ">":
      return month1 > month2 || (day1 > day2 && month1 === month2)
    case "<":
    return month1 < month2 || (day1 < day2 && month1 === month2)
    case "=":
      return day1 === day2 && month1 === month2
  }
}
