import { ActionIcon, Box, Button, Checkbox, Container, Flex, Group, Input, Modal, Pagination, SimpleGrid, Text, useMantineTheme } from "@mantine/core";
import { IconSearch, IconX } from '@tabler/icons';
import axios from "axios";
import { debounce, isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import EmptyIcon from "../../components/Icon/EmptyIcon";
import { systemNotifications } from "../../components/Notifications/Notifications";
import { BASE_URL_V4 } from "../../constants/api";
import ItemNode from "./ItemNode";
import classes from "./style.module.css";

function ModalCustomNode({
  show,
  setShow,
  filter,
  props,
  setFilter,
  lastPage,
  setLastPage,
  category,
  setCategory
}) {
  const theme = useMantineTheme();
  const [selected, setSelected] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [listCategory, setListCategory] = useState([
    { value: '-1', label: "All" },
    { value: 'all', label: "Your nodes" }
  ]);
  const lineRef = useRef(null);
  const tabsRef = useRef([]);

  const getListCategory = async function () {
    const { data } = await axios.get(BASE_URL_V4 + '/v4/node/list-category')
    if (!data) return
    const newCategory = Object.keys(data.content).map(i => ({
      value: i,
      label: data.content[i]
    }))
    setListCategory(category => ([...category, ...newCategory]))
  }

  useEffect(() => {
    getListCategory()
  }, [])

  const handleClose = () => {
    setShow(false)
  }

  const getCustomNodes = async function (params) {
    const { data } = await axios.get(BASE_URL_V4 + '/v4/node', { params })
    setNodes(data?.data.content?.map(i => ({
      data: {
        version: 5,
        type: "customNode",
        typeNode: "normalNode",
        label: i.name,
        icon: "IconSettingsAutomation",
        options: { ...i, description: undefined },
        search: i.name,
      },
      description: i.description,
    })))
    setLastPage(data.meta.last_page)
  }

  useEffect(() => {
    if (category === 'all') {
      getCustomNodes(filter)
    } else {
      getCustomNodesByCategory(filter)
    }
  }, [filter, category])

  const toggleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(items => items.filter(item => item !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const onToggleSelectAll = () => {
    if (isEmpty(selected) || selected.length < (nodes?.length || 0)) {
      setSelected(nodes?.map(item => item?.data.options.id) || [])
    } else if (selected.length === nodes?.length) {
      setSelected([])
    }
  }

  const isSelectedAll = !!selected.length && selected.length === nodes?.length

  const onSubmit = () => {
    if (!selected.length) {
      systemNotifications({
        type: 'error',
        color: 'red',
        message: 'Custom node invalid'
      });
      return
    }
    props.handleCustomNode(nodes.filter(item => selected.some(s => s === item.data.options.id)))
    setShow(false)
  }

  const onSearch = debounce((searchValue) => {
    setFilter(fil => ({ ...fil, search: searchValue, page: 1 }))
  }, 450)

  const getCustomNodesByCategory = async function (params) {
    const newParams = { ...params, category: category }
    if (category === '-1') {
      delete newParams.category
    }
    const { data } = await axios.get(BASE_URL_V4 + '/v4/node/store', { params: newParams })
    setNodes(data?.data.content?.map(i => ({
      data: {
        version: 5,
        type: "customNode",
        typeNode: "normalNode",
        label: i.name,
        icon: "IconSettingsAutomation",
        options: { ...i, description: undefined },
        search: i.name,
      },
      description: i.description,
    })))
    setLastPage(data.meta.last_page)
  }

  useEffect(() => {
    if (!listCategory) return
    const activeTab = tabsRef.current[category];
    if (activeTab && lineRef.current) {
      lineRef.current.style.left = `${activeTab.offsetLeft}px`;
      lineRef.current.style.width = `${activeTab.offsetWidth}px`;
    }
  }, [category, listCategory]);

  const handleTabClick = (e, value) => {
    e.preventDefault();
    setCategory(value);
  };

  const items = nodes.map((node, index) => (
    <ItemNode
      key={index}
      node={node}
      toggleSelect={toggleSelect}
      isSelected={selected.includes(node?.data.options.id)}
    />
  ));

  return (
    <Modal
      zIndex={1000}
      opened={show}
      onClose={handleClose}
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      withCloseButton={false}
      overlayOpacity={0.55}
      overlayBlur={0.5}
      size={900}
    >
      <Container style={{ overflow: "hidden" }}>
        <Group position="apart">
          <Group h="100%" position="left" spacing={0} style={{
            position: "relative"
          }}>
            {listCategory?.map(c => (
              <a
                key={c.value}
                href="#"
                style={{
                  color: theme.colorScheme === "dark" ? "#C1C2C5"
                    : theme.colors.dark[9]
                }}
                ref={(el) => (tabsRef.current[c.value] = el)}
                className={`${classes.link} ${category.includes(c.value) ? classes.active : ""}`}
                onClick={(e) => handleTabClick(e, c.value)}
              >
                {c.label}
              </a>
            ))}
            <div ref={lineRef} className={classes.line}></div>
          </Group>
          <ActionIcon>
            <IconX size={18} onClick={handleClose} />
          </ActionIcon>
        </Group>

        <Box style={{ paddingTop: "1rem", borderTop: "1px solid #ccc", marginTop: "1rem", paddingBottom: 16 }}>
          <Group justify="space-between" h="100%" position="apart">
            <Checkbox
              label={<Text style={{ cursor: "pointer" }}>Selected all</Text>}
              checked={isSelectedAll}
              indeterminate={!!selected.length && !isSelectedAll}
              onChange={() => onToggleSelectAll(0)}
            />
            <Input
              onChange={(e) => {
                onSearch(e.target.value);
              }}
              placeholder="Search by name"
              icon={<IconSearch size="1rem" />}
            />
          </Group>
        </Box>
        {nodes?.length ? (
          <div style={{ overflow: "auto", height: 400, padding: "0 8px 10px 8px" }}>
            <SimpleGrid cols={3}>
              {items}
            </SimpleGrid>
          </div>
        ) : (
          <div style={{ height: 400, paddingBottom: 20, }}>
            <Flex justify="center" direction="column" align="center" style={{ height: "100%" }}>
              <EmptyIcon
                width={50}
                height={50}
                fill={theme.colorScheme === "dark" ? "#C1C2C5"
                  : theme.colors.dark[9]}
              />
              <Text>No data</Text>
            </Flex>
          </div>
        )}

        <Flex justify="space-between" style={{ paddingTop: "1rem" }}>
          <Pagination
            total={lastPage}
            page={filter.page}
            onChange={(page) => setFilter(fil => ({ ...fil, page: page }))}
          />
          <Button onClick={onSubmit}>Submit</Button>
        </Flex>
      </Container>
    </Modal>
  );
}

export default ModalCustomNode;