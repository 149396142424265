import { Checkbox, SegmentedControl, Select, Switch } from "@mantine/core";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppSettingsContext } from "../../../../core/context/AppSettingsContext";
import VariableNumberInput from "../../../GlobalComponent/VariableNumberInput";
import VariableSelectCreate from "../../../GlobalComponent/VariableSelectCreate";
import VariableSelectWrite from "../../../GlobalComponent/VariableSelectWrite";
import { compareAppVersions } from "../../../../utils/compareAppVersion";
import { compareDateVersions } from "../../../../utils/compareDateVersion";

const LIST_MAIL_SERVICE = [
  {
    label: "Gmail",
    value: "imap.gmail.com"
  },
  {
    label: "Outlook/Hotmail",
    value: "outlook.office365.com"
  },
  {
    label: "Yahoo",
    value: "imap.mail.yahoo.com"
  },
  {
    label: "Custom",
    value: "custom"
  }
]

const defaultState = {
  mailService: 'imap.gmail.com',
  imapHost: '',
  imapPort: '',
  tlsSecure: true,
  email: '',
  password: '',
  mailBox: 'INBOX',
  unseenMailOnly: false,
  markMailAsRead: false,
  getLatestMail: false,
  mailContains: '',
  contentRegex: '',
  timeout: 30000,
  outputVariable: "",
  contentMailContains: '',
  outputMode: 'filter',
}

function ReadMail(props) {
  const [dataState, setDataState] = useState({ ...defaultState, ...props?.nodeData?.data?.options }); //(props?.nodeData?.data?.options ?? defaultState)
  const onCheckBox = (key) => (e) => {
    setDataState({ ...dataState, [key]: e.target.checked })
  }

  const isShowOutputMode = useMemo(() => {
    const appVersion = window.api.APP_VERSION
    const dateVersion = window.api.DATE_VERSION
    if (compareAppVersions(appVersion, '4.0.27') > 0) {
      return true
    }
    if (compareAppVersions('4.0.27', appVersion) === 0 && compareDateVersions('1703', '<', dateVersion)) {
      return true
    }
  }, [])

  const onChangeValue = (key, target) => e => {
    if (e?.type === 'change') {
      setDataState((state) => ({ ...state, [key]: target ? e.target[target] : e.target.value }))
    } else if (e?.type === 'click') {
      const newValue = e.newValue || ""
      setDataState((state) => ({ ...state, [key]: newValue }))
    } else {
      setDataState((state) => ({ ...state, [key]: e }))
    }
  }

  useEffect(() => {
    props.handleSetDataBaseModal({ ...dataState })
  }, [props, dataState])

  return (
    <>
      <Select
        label="Email service"
        placeholder="Pick value"
        data={LIST_MAIL_SERVICE}
        defaultValue="imap.gmail.com"
        value={dataState.mailService}
        onChange={value => setDataState((s) => ({ ...s, mailService: value }))}
      />
      {
        dataState.mailService === 'custom' && (
          <>
            <VariableSelectWrite
              placeholder="Your email"
              label="IMAP host"
              dataState={dataState.imapHost}
              handleData={onChangeValue('imapHost')}
              handleSelect={onChangeValue('imapHost')}
            />
            <VariableSelectWrite
              placeholder="Your Password"
              label="IMAP port"
              dataState={dataState.imapPort}
              handleData={onChangeValue('imapPort')}
              handleSelect={onChangeValue('imapPort')}
            />
            <Switch
              className="mt-3"
              label="TLS secure"
              checked={dataState.tlsSecure}
              onChange={event => setDataState((s) => ({ ...s, tlsSecure: event.target.checked }))}
            />
          </>
        )
      }
      <VariableSelectWrite
        placeholder="Your email"
        label="Email"
        dataState={dataState.email}
        handleData={onChangeValue('email')}
        handleSelect={onChangeValue('email')}
      />
      <VariableSelectWrite
        placeholder="Your Password"
        label="Password"
        dataState={dataState.password}
        handleData={onChangeValue('password')}
        handleSelect={onChangeValue('password')}
      />
      <VariableSelectWrite
        placeholder="INBOX"
        label="Mailbox"
        dataState={dataState.mailBox}
        handleData={onChangeValue('mailBox')}
        handleSelect={onChangeValue('mailBox')}
      />
      {
        isShowOutputMode && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '12px',
            }}
          >
            <SegmentedControl
              value={dataState.outputMode}
              data={[
                { label: 'Filter Data', value: 'filter' },
                { label: 'Get All Data', value: 'all' },
              ]}
              onChange={(value) => setDataState((s) => ({ ...s, outputMode: value }))}
            />
          </div>
        )
      }
      {
        dataState.outputMode === 'filter' ? (
          <>
            <div style={{ marginTop: '20px' }}>
              <Checkbox
                checked={dataState.unseenMailOnly}
                label="Unseen mail only"
                onChange={onCheckBox('unseenMailOnly')}
              />
              <Checkbox
                checked={dataState.markMailAsRead}
                label="Mark mail as read"
                onChange={onCheckBox('markMailAsRead')}
              />
              <Checkbox
                checked={dataState.getLatestMail}
                label="Get latest mail"
                onChange={onCheckBox('getLatestMail')}
              />
              {
                !dataState.getLatestMail && (
                  <VariableSelectWrite
                    placeholder="Email"
                    label="Sender email contains"
                    dataState={dataState.mailContains}
                    handleData={onChangeValue('mailContains')}
                    handleSelect={onChangeValue('mailContains')}
                  />
                )
              }
              <VariableSelectWrite
                placeholder="Regex"
                label="Content mail contains"
                dataState={dataState.contentMailContains}
                handleData={onChangeValue('contentMailContains')}
                handleSelect={onChangeValue('contentMailContains')}
              />
            </div>
          </>
        ) : null
      }
      <VariableSelectCreate
        label="Output variable"
        placeholder="Select variable"
        value={dataState.outputVariable}
        handleChange={onChangeValue("outputVariable")}
      />
      <VariableNumberInput
        label="Read mail timeout (milliseconds)"
        dataState={dataState.timeout}
        handleData={onChangeValue("timeout")}
        isMillisecond={true}
      />
    </>
  );
}


export default ReadMail;