import { v4 as uuid } from "uuid";

function convertFromAIGenerateToScript({content, position, layout='vertical'}) {
    const newPosition = { x: position.x, y: position.y };
    const nodes = []
    const edges = []
    let previousUuid = ''

    for (const item of content) {
        let { data, selector, output, target, path, name, value } = item;
        data = data?.replaceAll(/\{(\w+)\}/g, '${$1}');
        selector = selector?.replaceAll(/\{(\w+)\}/g, '${$1}');
        selector = selector?.replaceAll("xpath=", "")
        output = output?.replaceAll(/\{(\w+)\}/g, '${$1}');
        target = target?.replaceAll(/\{(\w+)\}/g, '${$1}');
        path = path?.replaceAll(/\{(\w+)\}/g, '${$1}');
        name = name?.replaceAll(/\{(\w+)\}/g, '${$1}');
        value = value?.replaceAll(/\{(\w+)\}/g, '${$1}');
        let node = {};
        const newUuid = uuid();
        let nodeSize = { width: 0, height: 0 }

        switch (item.type) {
            case "newTab":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "newTab",
                        typeNode: "normalNode",
                        label: "New tab",
                        icon: "IconExternalLink",
                        options: {
                            newTab: data || ""
                        },
                        search: "New tab"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "activeTab":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: "2acea61f-1700-4567-8d7c-26b10ce33744",
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "activeTab",
                        typeNode: "normalNode",
                        label: "Active tab",
                        icon: "IconLayersLinked",
                        options: {
                            title: "url",
                            comparison: "equal",
                            tabInfo: data || ""
                        },
                        search: "Active tab"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "closeTab":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "closeTab",
                        typeNode: "normalNode",
                        label: "Close tab",
                        icon: "IconSquareX",
                        options: {
                            closeTabType: data,
                            tabNumber: 1
                        },
                        search: "Close tab"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "openUrl":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "openUrl",
                        typeNode: "normalNode",
                        label: "Open url",
                        icon: "IconLink",
                        options: {
                            url: data,
                            timeout: 30000,
                            waitUntil: "domcontentloaded",
                            referer: ""
                        },
                        search: "Open url"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "reloadPage":
                nodeSize = { width: 131, height: 46 };
                node = {
                    width: 131,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "reloadPage",
                        typeNode: "normalNode",
                        label: "Reload page",
                        icon: "IconRefresh",
                        options: {
                            reload: true,
                            timeout: 30000
                        },
                        search: "Reload page"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "screenShot":
                nodeSize = { width: 123, height: 46 };
                node = {
                    width: 123,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "screenShot",
                        typeNode: "normalNode",
                        label: "Screenshot",
                        icon: "IconScreenshot",
                        options: {
                            screen_shot_name: "1740545565233",
                            isFullScreen: false,
                            type_format: "jpeg",
                            modeSave: "default",
                            path_folder: path
                        }
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "switchFrame":
                nodeSize = { width: 136, height: 46 };
                node = {
                    width: 136,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "switchFrame",
                        typeNode: "normalNode",
                        label: "Switch Frame",
                        icon: "IconFrame",
                        options: {
                            elementSelect: data,
                            frame: "subFrame",
                            timeout: "30000"
                        }
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "switchExtensionPopup":
                nodeSize = { width: 196, height: 46 };
                node = {
                    width: 196,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "switchExtensionPopup",
                        typeNode: "normalNode",
                        label: "Switch Extension popup",
                        icon: "IconPuzzle",
                        options: {
                            switchPage: "extensionPopupPage",
                            elementSelect: data,
                            timeout: 30000
                        }
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "goBack":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "goBack",
                        typeNode: "normalNode",
                        label: "Go back",
                        icon: "IconArrowBadgeLeft",
                        options: {
                            timeout: 0
                        },
                        search: "Go back"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "click":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "click",
                        typeNode: "normalNode",
                        label: "Click",
                        icon: "IconHandClick",
                        options: {
                            button: "left",
                            buttonClick: "click",
                            selectorType: "selector",
                            elementSelect: selector,
                            elementOrder: "fixed",
                            fixedElement: 1,
                            randomElementFrom: 1,
                            randomElementTo: 50,
                            x: 0,
                            y: 0
                        },
                        search: "Click"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "mouseMovement":
                nodeSize = { width: 164, height: 46 };
                node = {
                    width: 164,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "mouseMovement",
                        typeNode: "normalNode",
                        label: "Mouse movement",
                        icon: "IconDragDrop",
                        options: {
                            selectorType: "selector",
                            elementSelect: selector,
                            elementOrder: "fixed",
                            fixedElement: 1,
                            randomElementFrom: 1,
                            randomElementTo: 50,
                            x: 0,
                            y: 0,
                            focus: false
                        },
                        search: "Mouse movement"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "scroll":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "scroll",
                        typeNode: "normalNode",
                        label: "Scroll",
                        icon: "IconGripVertical",
                        options: {
                            selectorType: "selector",
                            elementSelect: selector,
                            fixedElement: 1,
                            deltaX: 0,
                            deltaY: 0
                        },
                        search: "Scroll"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "drop-down-selector":
                nodeSize = { width: 174, height: 46 };
                node = {
                    width: 174,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "drop-down-selector",
                        typeNode: "normalNode",
                        label: "Drop-down selector",
                        icon: "IconSelect",
                        options: {
                            elementSelect: selector,
                            elementOrder: 'fixed',
                            fixedElement: 1,
                            randomElementFrom: 1,
                            randomElementTo: 50,
                            selectedValue: value,
                        },
                        search: "Drop-down selector"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "mouseWheel":
                nodeSize = { width: 136, height: 46 };
                node = {
                    width: 136,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "mouseWheel",
                        typeNode: "normalNode",
                        label: "Mouse wheel",
                        icon: "IconMouse",
                        options: {
                            deltaX: 0,
                            deltaY: target,
                        },
                        search: "wheel"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "pressKey":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "pressKey",
                        typeNode: "normalNode",
                        label: "Press key",
                        icon: "IconKeyboardShow",
                        options: {
                            pressKey: data?.split(",").map(i => i.trim()),
                        },
                        search: "Press key"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "typeText":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "typeText",
                        typeNode: "normalNode",
                        label: "Type text",
                        icon: "IconBallpen",
                        search: "Type text",
                        options: {
                            intervalTime: 100,
                            typeText: data,
                        },
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "sendTextToSelector":
                nodeSize = { width: 177, height: 46 };
                node = {
                    width: 177,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "sendTextToSelector",
                        typeNode: "normalNode",
                        label: "Send text to selector",
                        icon: "IconAlphabetLatin",
                        options: {
                            elementSelect: selector,
                            fixedElement: 1,
                            content: data,
                            contentType: "sequence",
                            intervalTime: 100,
                            timeout: 30000
                        },
                        search: "Send text to selector"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "elementExists":
                nodeSize = { width: 141, height: 46 };
                node = {
                    width: 141,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "elementExists",
                        typeNode: "normalNode",
                        label: "Element exists",
                        icon: "IconMinimize",
                        options: {
                            elementSelect: selector,
                            fixedElement: 1,
                            isVisible: "true",
                            timeout: 30000
                        },
                        search: "Element exists"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "getUrl":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "getUrl",
                        typeNode: "normalNode",
                        label: "Get URL",
                        icon: "IconLine",
                        options: {
                            extractionType: "fullUrl",
                            searchKeyValue: "",
                            dataUrl: output,
                        },
                        search: "Get URL"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "getText":
                nodeSize = { width: 120, height: 46 };
                node = {
                    width: 120,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "getText",
                        typeNode: "normalNode",
                        label: "Get text",
                        icon: "IconFileDots",
                        options: {
                            elementSelect: selector,
                            elementOrder: "fixed",
                            fixedElement: 1,
                            randomElementFrom: 1,
                            randomElementTo: 50,
                            outputVariable: output
                        },
                        search: "Get text"
                    },
                    selected: true,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            case "getAttributeValue":
                nodeSize = { width: 166, height: 46 };
                node = {
                    width: 166,
                    height: 46,
                    id: newUuid,
                    type: "normalNode",
                    position: { ...newPosition },
                    data: {
                        version: 0,
                        type: "getAttributeValue",
                        typeNode: "normalNode",
                        label: "Get attribute value",
                        icon: "Icon3dCubeSphere",
                        options: {
                            attributeName: name,
                            outputVariable: output,
                            elementSelect: selector,
                            elementOrder: "fixed",
                            fixedElement: 1,
                            randomElementFrom: 1,
                            randomElementTo: 50
                        },
                        search: "Get attribute value"
                    },
                    selected: false,
                    positionAbsolute: { ...newPosition },
                    dragging: false
                }
                break;
            default:
                console.log("Invalid type: ", item);
                break;
        }

        if (layout === 'vertical') {
            newPosition.y = newPosition.y + 46 * 2
        } else {
            newPosition.x = newPosition.x + newPosition.x * 2
        }

        node.data.options.nodeSetting = {
            fromTimeSleep: 3000,
            toTimeSleep: 7000,
        }
        nodes.push(node)
        if (previousUuid) {
            edges.push({
                source: previousUuid,
                sourceHandle: `success-${previousUuid}`,
                target: `${newUuid}`,
                targetHandle: `in-${newUuid}`,
                type: "customEdge",
                style: {
                    stroke: "var(--output)"
                },
                data: {},
                id: `reactflow__edge-${previousUuid}out-${previousUuid}-${newUuid}in-${newUuid}`,
                selected: false
            });
        }
        previousUuid = newUuid;
    }

    return { nodes: nodes, edges: edges}
}

export { convertFromAIGenerateToScript };
