import PressKeyDoc from "./Doc/PressKey";
import ActiveTabDoc from "./Doc/ActiveTab";
import NewTabDoc from "./Doc/NewTab";
import CloseTabDoc from "./Doc/CloseTab";
import OpenUrlDoc from "./Doc/OpenUrl";
import ReloadPageDoc from "./Doc/ReloadPage";
import ScreenShotDoc from "./Doc/ScreenShot";
import SwitchFrameDoc from "./Doc/SwitchFrame";
import SwitchExtensionPopupDoc from "./Doc/SwitchExtensionPopup";
import GoBackDoc from "./Doc/GoBack";
import ClickDoc from "./Doc/Click";
import MouseMovementDoc from "./Doc/MouseMovement";
import ScrollDoc from "./Doc/Scroll";
import DropDownSelectorDoc from "./Doc/DropDownSelector";
import MouseWheelDoc from "./Doc/MouseWheel";
import TypeTextDoc from "./Doc/TypeText";
import SendTextToSelectorDoc from "./Doc/SendTextToSelector";
import ElementExistsDoc from "./Doc/ElementExists";
import GetUrlDoc from "./Doc/GetUrl";
import GetTextDoc from "./Doc/GetText";
import GetValueDoc from "./Doc/GetValue";
import GetAttributeValueDoc from "./Doc/GetAttributeValue";
import RandomDoc from "./Doc/Random";
import FileUploadDoc from "./Doc/FileUpload";
import FileChooseDoc from "./Doc/FileChoose";
import ReadFileDoc from "./Doc/ReadFile";
import WriteFileDoc from "./Doc/WriteFile";
import HttpDoc from "./Doc/Http";
import SetVariableDoc from "./Doc/SetVariable";
import AuthenticatorCodeDoc from "./Doc/AuthenticatorCode";
import ConvertJsonDoc from "./Doc/ConvertJson";
import DownloadFileDoc from "./Doc/DownloadFile";
import ExtractionInTextDoc from "./Doc/ExtractionInText";
import SetCookiesDoc from "./Doc/SetCookies";
import GetCookiesDoc from "./Doc/GetCookies";
import ClearCookiesDoc from "./Doc/ClearCookies";
import ImageSearchDoc from "./Doc/ImageSearch";
import ImageSearchV2Doc from "./Doc/ImageSeawrchV2";
import SetDataDoc from "./Doc/SetData";
import GetDataDoc from "./Doc/GetData";
import ReadGoogleSheetDoc from "./Doc/ReadGoogleSheet";
import SpreadsheetDoc from "./Doc/Spreadsheet";
import WriteSheetDoc from "./Doc/WriteSheet";
import PauseCodeDoc from "./Doc/PauseCode";
import IfCodeDoc from "./Doc/IfCode";
import EvalCodeDoc from "./Doc/EvalCode";
import WhileCodeDoc from "./Doc/WhileCode";
import ForCodeDoc from "./Doc/ForCode";
import AddCommentDoc from "./Doc/AddComment";
import UpdateProfileDoc from "./Doc/UpdateProfile";
import StopLoopDoc from "./Doc/StopLoop";
import StopCodeDoc from "./Doc/StopCode";
import AddLogDoc from "./Doc/AddLog";
import RunOtherScriptDoc from "./Doc/RunOtherScript";
import DisconnectionDoc from "./Doc/Disconnection";
import SetUserAgentDoc from "./Doc/SetUserAgent";
import DeleteFileDoc from "./Doc/DeleteFile";
import ReadMailDoc from "./Doc/ReadMail";
import ReadMailOAuth2Doc from "./Doc/ReadMailOAuth2";

export const GetDocumentModalComponent = {
    //Navigator
    'newTab' : NewTabDoc,
    'activeTab' : ActiveTabDoc,
    'closeTab' : CloseTabDoc,
    'openUrl' : OpenUrlDoc,
    'reloadPage' : ReloadPageDoc,
    'screenShot' : ScreenShotDoc,
    'switchFrame' : SwitchFrameDoc,
    'switchExtensionPopup' : SwitchExtensionPopupDoc,
    'goBack' : GoBackDoc,

    //Mouse
    'click' : ClickDoc,
    'mouseMovement' : MouseMovementDoc,
    'scroll' : ScrollDoc,
    'drop-down-selector' : DropDownSelectorDoc,
    'mouseWheel' : MouseWheelDoc,

    //Keyboard
    'pressKey' : PressKeyDoc,
    'typeText' : TypeTextDoc,
    'sendTextToSelector' : SendTextToSelectorDoc,

    //Data
    'elementExists' : ElementExistsDoc,
    'getUrl' : GetUrlDoc,
    'getText' : GetTextDoc,
    'getValue' : GetValueDoc,
    'getAttributeValue' : GetAttributeValueDoc,
    'random' : RandomDoc,
    'fileUpload' : FileUploadDoc,
    'fileChoose' : FileChooseDoc,
    'readFile' : ReadFileDoc,
    'writeFile' : WriteFileDoc,
    'http' : HttpDoc,
    'setVariable' : SetVariableDoc,
    'authenticatorCode' : AuthenticatorCodeDoc,
    'convertJson' : ConvertJsonDoc,
    'downloadFile' : DownloadFileDoc,
    'extractionInText' : ExtractionInTextDoc,
    'setCookies' : SetCookiesDoc,
    'getCookies' : GetCookiesDoc,
    'clearCookies' : ClearCookiesDoc,
    'imageSearch' : ImageSearchDoc,
    'imageSearchV2' : ImageSearchV2Doc,
    'setData' : SetDataDoc,
    'getData' : GetDataDoc,
    'readGoogleSheet' : ReadGoogleSheetDoc, //spreadsheet V1
    'spreadsheet' : SpreadsheetDoc, //spreadsheet V2
    'writeSheet' : WriteSheetDoc,
    'setUserAgent' : SetUserAgentDoc,
    'deleteFile' : DeleteFileDoc,
    'readMail' : ReadMailDoc,
    'readMailOAuth2Doc': ReadMailOAuth2Doc,
    'readMailOauth2': ReadMailOAuth2Doc,

    //Orther
    'pauseCode' : PauseCodeDoc,
    'ifCode' : IfCodeDoc,
    'evalCode' : EvalCodeDoc,
    'whileCode' : WhileCodeDoc,
    'forCode' : ForCodeDoc,
    'addComment' : AddCommentDoc,
    'updateProfile' : UpdateProfileDoc,
    'stopLoop' : StopLoopDoc,
    'stopCode' : StopCodeDoc,
    'addLog' : AddLogDoc,
    'runOtherScript' : RunOtherScriptDoc,
    'disconnection' : DisconnectionDoc,
    // 'runCommand' : runCommandDoc
}